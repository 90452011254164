<template>
    <div class="my-content">
        <div class="my-header"></div>
        <div class="my-bottom">
            <div class="bottom-middle">
                <div class="header-head">
                    <img :src="imgSrc" width="100%"/>
                </div>
                <span class="header-name">{{name}}</span>
            </div>
            <div class="bottom-content">
                <div class="content-list">
                    <div class="list">
                        <span class="list-left">
                            <img :src="require('../../assets/discount.png')" />
                            <div class="list-left-text">优惠券</div>
                        </span>
                        <i class="iconfont icon-tiaozhuan"></i>
                    </div>
                    <div class="list" @click="handleCollect">
                        <span class="list-left"
                              @click="handleCollect">
                            <img :src="require('../../assets/collect.png')" />
                            <span class="list-left-text">我的收藏</span>
                        </span>
                        <i class="iconfont icon-tiaozhuan"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  data() {
    return {
      name: '一串名字',
      imgSrc: ''
    };
  },
  created() {
    console.log(window, '999')
    let userInfo = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : { imgSrc: '', name: '' }
    this.name = userInfo.user_name
    this.imgSrc = userInfo.head_img_url
  },
  methods: {
    handleCollect() {
        this.$router.push({path: '/collect'})
    },
  },
};
</script>

<style lang="scss" scoped>
.my-content {
	width: 100%;
	background: #fff;
	.my-header {
		height: 3rem;
		background: #4b77b0;
	}
	.my-bottom {
		// height: 12.16rem;
		border-radius: 12px 12px 0 0;
		margin-top: -0.8rem;
		position: relative;
		background: #fff;
		.bottom-middle {
			position: absolute;
			margin-top: -0.8rem;
			margin-left: 0.72rem;
			display: flex;
			flex-direction: row;
			.header-head {
				width: 1.6rem;
				height: 1.6rem;
				border-radius: 50%;
				// background: red;
                overflow: hidden;
			}
			.header-name {
				font-size: 0.36rem;
				color: #fff;
				margin-left: 1.82rem;
				justify-items: center;
				position: absolute;
				width: 1.44rem;
				line-height: 0.52rem;
				top: 0.2rem;
			}
		}
		.content-list {
			height: 100%;
			padding: 1.02rem 0.24rem 0 0.4rem;
			.list {
				height: 1.2rem;
				// width: 6.86rem;
				margin-top: 0.24rem;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				border-radius: 0.16rem;
				box-shadow: 0 0.04rem 0.26rem 0 #dedddd;
				.list-left {
					margin-left: 0.24rem;
					display: flex;
					flex-direction: row;
					align-items: center;
					.list-left-text {
						font-weight: 400;
						color: #333333;
						font-size: 0.28rem;
						margin-left: 0.08rem;
					}
				}
				.icon-tiaozhuan {
					margin-right: 0.24rem;
					font-size: 0.32rem;
				}
			}
		}
	}
}
</style>
